export default {
  data() {
    return {
      error: {
        companyName: false,
        companyAddress: false,
        phoneNumber: false,
        taxID: false,
        firstName: false,
        lastName: false,
        accounting: false,
        email: false,
        password: false,
        confirmPassword: false,
        website: false,
        accounts: false,
        terms: false,
        countryCode: false,
        stateCode: false,
        city: false,
        zipCode: false,
        is_branded: false
      },
    };
  },
  methods: {
    validationsError() {
      if (!this.accountForm.company_name) {
        this.error.companyName = true;
      } else {
        this.error.companyName = false;
      }
      if (!this.accountForm.company_address) {
        this.error.companyAddress = true;
      } else {
        this.error.companyAddress = false;
      }
      if (!this.accountForm.phone) {
        this.error.phoneNumber = true;
      } else {
        this.error.phoneNumber = false;
      }
      if (!this.accountForm.first_name) {
        this.error.firstName = true;
      } else {
        this.error.firstName = false;
      }
      if (!this.accountForm.lastname) {
        this.error.lastName = true;
      } else {
        this.error.lastName = false;
      }
      if (!this.accountForm.email) {
        this.error.email = true;
      } else {
        this.error.email = false;
      }
      if (!this.accountForm.password) {
        this.error.password = true;
      } else {
        this.error.password = false;
      }
      if (!this.accountForm.confirm_password) {
        this.error.confirmPassword = true;
      } else {
        this.error.confirmPassword = false;
      }
      if (!this.accountForm.country_code) {
        this.error.countryCode = true;
      } else {
        this.error.countryCode = false;
      }
      if (!this.accountForm.city) {
        this.error.city = true;
      } else {
        this.error.city = false;
      }
      if (!this.accountForm.state_code) {
        this.error.stateCode = true;
      } else {
        this.error.stateCode = false;
      }
      if (!this.accountForm.zip_code) {
        this.error.zipCode = true;
      } else {
        this.error.zipCode = false;
      }
      if (!this.accountForm.accounts) {
        this.error.accounts = true;
      } else {
        this.error.accounts = false;
      }
    },
    validateEmails() {
      // eslint-disable-next-line max-len
      const regexp = new RegExp(/^.*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(?:\.[a-zA-Z0-9-]+)*$/);
      if (regexp.test(this.accountForm.email)) {
        return true;
      }
      this.error.email = true;
      return false;
    }
  },
};
